import React, { useState } from 'react'
// import loadable from '@loadable/component'
import { graphql } from 'gatsby'

// import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Layout from '../layout'
import PageHead from '../layout/PageHead'
import CaseGrid from '../components/Wrappers/CaseGrid'
import CaseCard from '../components/CardItems/CaseCard'
import TestimonialCard from '../components/CardItems/TestimonialCard'
import PageHeader from '../components/Sections/PageHeader'
import tabStyles from '../components/Blocks/tabs.module.sass'
import { tabSection } from '../components/Sections/tab-section.module.sass'
import CaseIndexHero from '../components/CaseSections/CaseIndexHero'
// const LazyCaseIndexHero = loadable(() => import('../components/CaseSections/CaseIndexHero'))
// const CaseIndexHero = props => <LazyCaseIndexHero {...props} />
import Filters from '../components/Blocks/Filters'
import TestimonialGrid from '../components/Wrappers/TestimonialGrid'

// const LazyFilters = loadable(() => import('../components/Blocks/Filters'))
// const Filters = props => <LazyFilters {...props} />

// import TestimonialGrid from '../components/Wrappers/TestimonialGrid'

const CaseStudiesPage = ({ data }) => {
  const caseStudies = data.caseStudies.nodes
  const testimonials = data.testimonials.nodes
  // const [caseFilter, setCaseFilter] = useState(null)
  const [testimonialFilter, setTestimonialFilter] = useState('Featured')
  // const getTags = elements => {
  //   return [...new Set(flatten(elements.map(item => item.tags)).filter(Boolean))]
  // }
  // const caseTags = getTags(caseStudies)
  const testimonialTags = ['Featured', 'Code Quality', 'Business Value', 'Development Process'] //getTags(testimonials)
  const page = {
    title: `SoftKraft - Case Studies`,
    description: `SoftKraft - Case Studies`
  }
  const matchTags = (current, target) => {
    return [target].filter(Boolean).every(tag => (current || []).includes(tag))
  }
  return (
    <Layout path={'/case-studies/'}>
      <PageHead {...page} />
      <PageHeader dark={false} path={'/case-studies/'} />
      <CaseIndexHero />
      <Tabs>
        <div className={tabStyles.tabs}>
          <TabList className="container">
            <Tab>Case studies</Tab>
            <Tab>Testimonials</Tab>
          </TabList>
        </div>
        <section className={classNames(tabSection, 'py-5')}>
          <TabPanel>
            <CaseGrid customClass="container">
              {caseStudies.map(caseStudy => (
                <CaseCard key={caseStudy.path} {...caseStudy} />
              ))}
            </CaseGrid>
          </TabPanel>
          <TabPanel className="container">
            <Filters tags={testimonialTags} filter={testimonialFilter} setFilter={setTestimonialFilter} />
            <TestimonialGrid>
              {testimonials
                .filter(testimonial => matchTags(testimonial.tags, testimonialFilter))
                .map((testimonial, i) => (
                  <TestimonialCard key={i} {...testimonial} />
                ))}
            </TestimonialGrid>
          </TabPanel>
        </section>
      </Tabs>
    </Layout>
  )
}

// CaseStudiesPage.propTypes = {
//   data: PropTypes.shape({
//     caseStudies: PropTypes.array
//   })
// }

export default CaseStudiesPage

export const query = graphql`
  query CaseStudies {
    caseStudies: allCaseStudiesYaml(limit: 1000, sort: { fields: [date], order: DESC }) {
      totalCount
      nodes {
        path
        title
        description
        thumb
        tags
        featured
        background
      }
    }
    testimonials: allTestimonialsYaml(limit: 1000) {
      nodes {
        background
        text
        image
        name
        position
        tab
        tags
      }
    }
  }
`
