import React from 'react'
import loadable from '@loadable/component'

import Image from '../Image'
// import WorldIllustration from '../../../static/backgrounds/world-illustration.inline.svg'
import classNames from 'classnames'
import styles from './case-index-hero.module.sass'

const LazyClutchWidget = loadable(() => import('../Blocks/ClutchWidget'))
const ClutchWidget = props => <LazyClutchWidget {...props} />

export default function CaseIndexHero({ children }) {
  return (
    <section className={styles.caseIndexHero}>
      <div className="container">
        <div className="row">
          <div className={classNames(styles.heroEntry, 'col-md')}>
            <h1 className={styles.heroTitle}>
              60+ Clients <em>Have Built Their Cloud-based Solutions</em> With Us
            </h1>
            {children}
          </div>
          <div className={classNames(styles.heroAsset, 'col-md')}>
            <ClutchWidget />
          </div>
        </div>
      </div>
      <Image className={styles.heroBg} src="/ui/world-illustration.svg" wrapper />
      {/* <WorldIllustration className={styles.heroBg} /> */}
    </section>
  )
}
