import React from 'react'
import classNames from 'classnames'
// import Flickity from 'react-flickity-component'
// import 'flickity/css/flickity.css'
import styles from './filters.module.sass'

// const flickityOptions = {
//   pageDots: false,
//   prevNextButtons: false,
//   cellAlign: 'left',
//   contain: true,
//   draggable: true,
//   freeScroll: true,
//   on: {
//     dragStart: function(e) {
//       e.target.classList.add('is-dragged')
//     },
//     dragEnd: function(e) {
//       e.target.classList.remove('is-dragged')
//       e.target.querySelector('.is-dragged') && e.target.querySelector('.is-dragged').classList.remove('is-dragged')
//     }
//   }
// }

const ConditionalSlider = ({ condition, slider, wrapper, children }) => (condition ? slider(children) : wrapper(children))

export default function Filters({ tags, filter, setFilter, slider, allTag, classes }) {
  return (
    <ConditionalSlider
      // condition={typeof slider === 'undefined' ? tags.length > 7 : slider}
      condition={false}
      slider={children => (
        <div>{children}</div>
        // <Flickity className={classNames(styles.filterList, classes)} options={flickityOptions}>
        //   {children}
        // </Flickity>
      )}
      wrapper={children => <div className={classNames(styles.filterList, classes)}>{children}</div>}
    >
      {[...(allTag ? [null] : [])].concat(tags).map((item, id) => {
        return (
          <button
            className={classNames(styles.filterItem, filter === item && styles.filterActive)}
            key={id}
            onClick={() => setFilter(item)}
            title={item || allTag}
          >
            {item || allTag}
          </button>
        )
      })}
    </ConditionalSlider>
  )
}
